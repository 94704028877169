import { useState, useEffect, useCallback } from 'react';
import { cancelOffer } from '../../api/upsideSupportBffClient';

export function useCancellationManager(offer) {
  var [state, setState] = useState({
    isCancelling: false,
    cancelResult: null,
  });

  var { isCancelling, cancelResult } = state;

  // Reset on new offer.
  useEffect(() => {
    setState({
      isCancelling: false,
      cancelResult: null,
    });
  }, [offer]);

  var cancelOfferCallback = useCallback(
    async (cancelReason) => {
      if (!offer || isCancelling) {
        return;
      }

      setState((prev) => {
        return { ...prev, isCancelling: true };
      });

      try {
        var result = await cancelOffer(offer.offerId, cancelReason);
        setState((prev) => {
          return { ...prev, cancelResult: result, isCancelling: false };
        });
      } catch (error) {
        setState((prev) => {
          return {
            ...prev,
            cancelResult: {
              success: false,
              message: error.message,
            },
            isCancelling: false,
          };
        });
      }
    },
    [offer, isCancelling, cancelOffer, setState]
  );

  return {
    isCancelling,
    cancelResult,
    cancelOffer: cancelOfferCallback,
  };
}

export default useCancellationManager;
