import { useCallback, useEffect, useState } from 'react';
import { getCancellableOffers } from '../../api/upsideSupportBffClient';

export function useCancellableOffers(vin) {
  const [state, setState] = useState({
    isLoading: false,
    cancellableOffers: [],
    error: null,
   });
  const [keeper, setKeeper] = useState({});

  useEffect(() => {
    var fetchData = async () => {
      if (vin) {
        setState((prev) => {
          return { ...prev, isLoading: true };
        });
        try {
          var cancellableOffers = await getCancellableOffers(vin);
          setState((prev) => {
            return { ...prev, isLoading: false, cancellableOffers };
          });
        }
        catch (error) {
          setState((prev) => {
            return { ...prev, isLoading: false, error: error.message };
          });
        }
      }
    };

    fetchData();
  }, [vin, keeper]);

  var { isLoading, cancellableOffers, error } = state;

  var refreshOffers = useCallback(() => {
    if (isLoading) {
      return;
    }
    setKeeper({});
  }, [keeper, isLoading]);

  return {
    isLoading,
    cancellableOffers,
    error,
    refreshOffers,
  };
}
