import { useMemo } from 'react';
import { Button, Stack, Spinner, Table } from 'react-bootstrap';

export function CancelOffersView({
  vin,
  isLoading,
  error,
  offers,
  onCancelOfferClick,
}) {
  var content = useMemo(() => {
    if (isLoading) {
      return (
        <div style={{ margin: '5rem auto', width: '6rem' }}>
          <Spinner
            data-testid="loading-offers-spinner"
            animation="border"
            style={{
              height: '6rem',
              width: '6rem',
            }}
          />
        </div>
      );
    }

    var rows = [];
    if (error) {
      rows = [
        <tr data-testid="error-row" key="error">
          <td colSpan={4}>Error: {error}</td>
        </tr>,
      ];
    } else if (offers && offers.length) {
      var rows = offers.map((offer) => {
        return (
          <tr key={offer.offerId} data-testid="offer-row">
            <td>{offer.vin}</td>
            <td>
              {offer.dealerLogicalId} / {offer.manheimSellerNumber}
            </td>
            <td>${offer.upsideMinimum}</td>
            <td>
              <Button
                data-testid="cancel-offer-btn"
                variant="danger"
                size="sm"
                onClick={() => {
                  onCancelOfferClick(offer);
                }}
              >
                Cancel Offer
              </Button>
            </td>
          </tr>
        );
      });
    } else {
      rows = [
        <tr data-testid="no-offers-row" key="empty">
          <td colSpan={4}>No Upside offers found for VIN {vin}</td>
        </tr>,
      ];
    }

    return (
      <Table data-testid="cancel-offers-table" striped bordered hover>
        <thead>
          <tr>
            <th>VIN</th>
            <th>Dealer</th>
            <th>Upside Minimum</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    );
  }, [vin, isLoading, offers, error, onCancelOfferClick]);

  return (
    <Stack gap={3} data-testid="cancel-offers-view">
      <h4>Cancel Offers</h4>
      <div>
        Cancels Upside offers that have not yet been listed into the Upside
        Direct sale and moves the vehicles back to Needs Minimum.
      </div>
      <div>{content}</div>
    </Stack>
  );
}

export default CancelOffersView;
