import React, { useMemo, useState } from 'react';
import { Button, Modal, Stack, Spinner } from 'react-bootstrap';

export function CancelOfferModal({
  offer,
  isCancelling,
  cancelResult,
  onCancelOffer,
  onClose,
}) {
  var [cancelReason, setCancelReason] = useState('');

  var modalContent = useMemo(() => {
    var { vin, dealerLogicalId, manheimSellerNumber } = offer;

    if (cancelResult) {
      var { success, message } = cancelResult;
      return (
        <>
          <Modal.Header>
            <Modal.Title data-testid="cancel-result-title">
              {success ? (
                <span data-testid="cancel-result-title-success">Offer Cancelled</span>
              ) : (
                <span data-testid="cancel-result-title-failure">Unable to Cancel Offer</span>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body data-testid="cancel-result-body">
            <p>{message}</p>
          </Modal.Body>
          <Modal.Footer data-testid="cancel-result-footer">
            <Button
              data-testid="close-modal-btn"
              variant="secondary"
              onClick={() => onClose()}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      );
    }

    return (
      <>
        <Modal.Header data-testid="cancel-offer-title">
          <Modal.Title>Cancel Offer on {vin}</Modal.Title>
        </Modal.Header>
        <Modal.Body data-testid="cancel-offer-body">
          <Stack gap={3}>
            Are you sure that you want to cancel the offer on VIN {vin}?
            <div>
              <div>
                <b>Dealer:</b> {dealerLogicalId}
              </div>
              <div>
                <b>Seller Number:</b> {manheimSellerNumber}
              </div>
            </div>
            <input
              data-testid="cancel-offer-reason-input"
              placeholder="Enter an optional cancellation reason..."
              maxLength={100}
              disabled={isCancelling}
              value={cancelReason}
              onChange={(e) => setCancelReason(e.target.value)}
            />
          </Stack>
        </Modal.Body>
        <Modal.Footer data-testid="cancel-offer-footer">
          <Button
            data-testid="cancel-offer-btn"
            variant="primary"
            onClick={() => onCancelOffer(cancelReason)}
            disabled={isCancelling}
          >
            {isCancelling ? (
              <>
                <Spinner
                  data-testid="cancelling-spinner"
                  animation="border"
                  size="sm"
                />{' '}
                Cancelling
              </>
            ) : (
              'Confirm'
            )}
          </Button>
          <Button
            data-testid="cancel-modal-btn"
            variant="secondary"
            disabled={isCancelling}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </>
    );
  }, [
    offer,
    cancelReason,
    isCancelling,
    cancelResult,
    setCancelReason,
    onClose,
    onCancelOffer,
  ]);

  return (
    <Modal
      show={true}
      backdrop="static"
      keyboard={false}
      data-testid="cancel-offer-modal"
    >
      {modalContent}
    </Modal>
  );
}

export default CancelOfferModal;
