import { upsideFetch } from '../utils/fetch';

export async function getCancellableOffers(vin) {
  var url = '/api/logs/cancellable-offers?' + new URLSearchParams({ vin: vin });

  try {
    var response = await upsideFetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error(`Failed to retrieve cancellable offers for vin ${vin}`);
    }

    var cancellableOffersResponse = await response.json();
    return cancellableOffersResponse.cancellableOffers;
  } catch (error) {
    console.error(error);
    throw new Error(`Failed to retrieve cancellable offers for vin ${vin}`);
  }
}

export async function cancelOffer(offerId, cancelReason) {
  return new Promise((resolve) => {

    console.log("Cancelling offer %s with reason: %s", offerId, cancelReason);

    setTimeout(() => {
      resolve({
        success: true,
        message: `The offer has been successfully cancelled.`,
      });
    }, 2000);
  });
}

export default {
  getCancellableOffers,
};
