import { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useCancellableOffers } from './hooks/useCancellableOffers';
import { useCancellationManager } from './hooks/useCancellationManager';
import CancelOfferModal from './components/CancelOfferModal';
import CancelOffersView from './components/CancelOffersView';

export function CancelOffers({ vin }) {
  var { isLoading, cancellableOffers, error, refreshOffers } =
    useCancellableOffers(vin);

  var [targetOffer, setTargetOffer] = useState(null);

  var { isCancelling, cancelResult, cancelOffer } =
    useCancellationManager(targetOffer);

  return (
    <Container fluid className="cancel-offers-container py-3">
      <CancelOffersView
        vin={vin}
        isLoading={isLoading}
        offers={cancellableOffers}
        error={error}
        onCancelOfferClick={setTargetOffer}
      />
      {targetOffer && (
        <CancelOfferModal
          offer={targetOffer}
          isCancelling={isCancelling}
          cancelResult={cancelResult}
          onCancelOffer={cancelOffer}
          onClose={() => {
            setTargetOffer(null);
            refreshOffers();
          }}
        />
      )}
    </Container>
  );
}

export default CancelOffers;
